.container {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 1rem;
}
.container > span {
    font-size: 1rem;
}

.container > p {
    margin: 0;
    font-size: .85rem;
}

.container > form {
    margin-top: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 1.6rem;
    margin: 0;
    font-weight: 600;
}
.containerBtn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    gap: 1rem;
}

.btn {
    font-size: 14px;
    text-wrap: nowrap;
}