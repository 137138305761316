@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  animation: fade-in 0.5s ease-in-out;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* list-style: none; */
  /* margin: 0; */
  /* padding: 0; */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  background-color: #f5f6fa;
  margin: 0;
  overflow: hidden;
}

/* p {
  font-size: 1.125rem;
  line-height: 1.5rem;
} */

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
  line-height: 4rem;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 3.375rem;
}

h3 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.75rem;
}

h4 {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.6875rem;
}

h5 {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 2.5rem;
}

h6 {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.1875rem;
}

#app.pagebase {
  /* height: 100vh; */
  overflow: hidden;
}

.modal-open {
  overflow: hidden;
}

.modal-open > div > .modal {
  background-color: rgba(0, 0, 0, 0.6);
  display: block;
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: background-color 0.25s;
  width: 100vw;
  z-index: 50;
}

.alert {
  display: none;
  position: fixed;
  align-items: center;
  top: 5.5rem;
  width: 100vw;
  z-index: 50;
}

.alert-open > div > .alert {
  display: block;
}

:root {
  --blue-primary-color: #0f6eb5;
  --light-blue-primary-color: #ebf8ff;
  --green-alert-color: #3bcb76;
  --light-primary-color: #cde1f1;
  --light-primary-color-hover: #b6cada;
  --light-text-color: #52575c;
  --primary-color: #0f6eb5;
  --purple-color: #6e4df0;
  --red-alert-color: #ea2036;
  --red-alert-light-color: #fff1f3;
  --red-alert-hover-color: #cc1d31;
  --green-success-color: #44ab4a;
  --green-success-hover-color: #3a943f;
  --green-success-bg-color: #eafcf4;
  --green-success-border-color: #11b06b;
  --text-color: #4d4a4a;
  --heading-text-color: #25282b;
  --ultra-light-text-color: #f3f3f3;
  --divider-dashed-color: #ebeaee;
  --placeholder-text-color: #BCC0C5;
  --primary-500-color: #EBF7FE;
  --error-100-color: #FBE5E6;
  --error-500-color: #CF212A;
  /* font-size: calc(100vw * 16 / 1440); */
}

.tox-button.tox-button--icon.tox-browse-url {
  display: none;
}

label {
  font-size: 1rem;
  font-weight: 600;
}